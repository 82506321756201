<template>
  <div class="wrap">
    <div class="space left"></div>
    <div class="page-skill-text">
      <Nav :cateSub="info.cate_sub" />

      <Detail :info="info" :onRefresh="fetchInfo" />
    </div>
    <div class="space"></div>
  </div>
</template>

<script>
import Nav from '../SkillIndex/nav'
import Detail from './detail'

import { apiCourseDetial, apiChapterDetail } from '@/api/course'

export default {
  components: {
    Nav,
    Detail
  },
  data() {
    return {
      info: {}
    }
  },
  created() {
    this.fetchInfo()
  },
  methods: {
    async fetchInfo() {
      const { skillId } = this.$route.query
      if (!skillId) {
        return false
      }
      const { data } = await apiCourseDetial({
        course_id: skillId
      })
      this.info = data
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  display: flex;
  min-height: calc(100vh - 66px);
  .space {
    flex: 1;
    &.left {
      background-color: #f8f8f8;
    }
  }
  .page-skill-text {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    position: relative;
  }
}
</style>
