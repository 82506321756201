<template>
  <div class="detail-wrap">
    <div class="back-title">
      <span @click="handleNavAll">所有课程</span>
      <i class="iconfont icon-class-right"></i>
      <span @click="handleNavCourse">{{ info.cate_sub_name }}</span>
      <i class="iconfont icon-class-right"></i>
      <span>{{ info.name }}</span>
    </div>

    <div class="top">
      <div class="title">
        <span>
          {{ info.name }}
        </span>
      </div>
      <div class="detail">
        <div class="teacher">
          <i class="iconfont icon-teacher1"></i>
          <span>老师：{{ (info.teacher_info || {}).name }}</span>
        </div>
        <div class="like" @click="handleLike">
          <i
            class="f-icon"
            :class="[info.is_liked ? 'icon-likes' : 'icon-like']"
          ></i>
          <span>{{ info.like_cnt }}</span>
        </div>
        <!--        <div class="share">-->
        <!--          <i class="iconfont icon-share1"></i>-->
        <!--          <span>分享</span>-->
        <!--        </div>-->
      </div>
    </div>

    <div class="content ql-snow">
      <div class="ql-editor" v-html="info.remark"></div>
    </div>
  </div>
</template>

<script>
import { apiChapterLike } from '@/api/course'

export default {
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    onRefresh: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      is_like: false
    }
  },
  methods: {
    async handleLike() {
      const { msg, code } = await apiChapterLike({
        type: 1,
        // chapter_id: this.info.id,
        course_id: this.info.id
      })
      if (code == 200) this.is_like = true
      this.$message.success(msg)
      this.onRefresh()
    },

    handleNavAll() {
      this.$router.push({
        name: 'courseAllList'
      })
    },
    handleNavCourse() {
      this.$router.push({
        name: 'course',
        query: {
          cateMain: this.info.cate_main,
          cateSub: this.info.cate_sub
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
$grayFont: #a5a5ac;

.detail-wrap {
  flex: 1;
  margin-left: 46px;
  margin-top: 25px;
  .back-title {
    font-size: 14px;
    color: #a5a5ac;
    display: flex;
    padding-top: 10px;
    align-items: center;
    border-bottom: 1px solid #ebebf2;
    padding-bottom: 16px;
    > span {
      cursor: pointer;
      &:last-child {
        cursor: none;
        color: #1f1f40;
      }
      margin-right: 5px;
    }
    > i {
      margin-right: 5px;
    }
  }

  .top {
    margin-top: 18px;
    .title {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .detail {
      height: 37px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $grayFont;
      .teacher {
        flex: 1;
        > span {
          margin-left: 6px;
        }
      }
      .like {
        margin-right: 26px;
        cursor: pointer;
        > i {
          margin-right: 3px;
        }
      }
      .share {
        margin-right: 14px;
        > i {
          margin-right: 3px;
        }
      }
    }
  }

  .content {
    margin-top: 30px;
    margin-bottom: 100px;
    color: #1f1f40;
  }
}
</style>
